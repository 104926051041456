import { useContext } from 'react'

import { context as OperationAppContext } from 'shared/context/OperationApp'

const PageIndex: React.VFC = () => {
  const operationApp = useContext(OperationAppContext)

  return <p>Welcome {operationApp.currentOperator?.name} to ミライバライ management app!</p>
}

export default PageIndex
